
const params = (store) => {
  let PARAMS = store.getState().accessors['PARAMS'];

  if(!PARAMS) {
    let params = new URLSearchParams(window.location.search)

    let data = {
      allParams:    params.get('stages') && params.get('personas') && params.get('types'),
      stages:       params.get('stages'),
      personas:     params.get('personas'),
      types:        params.get('types'),
      threshold:    params.get('threshold'),
      groupMinSize: params.get('groupMinSize'),
      journey:      params.get('journey'),
      theme:        params.get('theme')
    }

    store.dispatch({ type: 'SET_PARAMS', data })
  }
}

export default params
