import axios from 'axios';
import util from 'config/util';

const endpoints = {
  development: {
    ruptive: 'http://localhost:3000/api',
    similarity: 'https://ruptiveml-qa.azurewebsites.net'
  },
  production: {
    ruptive: 'https://qamsft.ruptive.cx/api',
    similarity: 'https://ruptiveml-qa.azurewebsites.net'
  }
};

const endpoint = (api, path) => {
  return `${endpoints[process.env.NODE_ENV][api]}/${path}`
};

const Async = {
  get: (api, path, payload = {}) => {
    console.log(endpoint(api, path))
    return axios.get(endpoint(api, path), payload)
  },
  put: (api, path, payload = {}) => {
    return axios.put(endpoint(api, path), payload.body, payload.config)
  },
  post: (api, path, payload = {}) => {
    return axios.post(endpoint(api, path), payload.body, payload.config)
  },
  patch: (api, path, payload = {}) => {
    return axios.patch(endpoint(api, path), payload.body, payload.config)
  },
  delete: (api, path, payload = {}) => {
    return axios.delete(endpoint(api, path), payload.body, payload.config)
  }
}

export default Async
