import Home from './pages/home/Home3';
import Theme from './pages/themes/Theme';
import Themes from './pages/themes/Themes';
import NotFound from './pages/generic/NotFound';

const routes = [
  { path: "/theme",  name: "Theme",  component: Theme },
  { path: "/themes", name: "Themes", component: Themes },
  { path: "/",       name: "Home",   component: Home },

  { path: "/*", name: "NotFound", component: NotFound },
];

export default routes;
