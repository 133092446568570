import Async from 'services/Async';
import util from 'config/util';

const stage = (store) => {
  let RUPTIVE_DATA = store.getState().accessors['RUPTIVE_DATA'];
  let PARAMS       = store.getState().accessors['PARAMS']

  if(PARAMS && !RUPTIVE_DATA) {
    // Get similarity data
    if(PARAMS.allParams) {
      Async.get('ruptive', `api/similarities/${PARAMS.stages}/${PARAMS.personas}/${PARAMS.types}`)
      .then(({ data }) => {

        let promises = []

        for(let d of data) {
          if(PARAMS.threshold) {
            d.data.threshold = parseFloat((PARAMS.threshold * .01).toFixed(2))
          }
          if(PARAMS.groupMinSize) {
            d.data.groupMinSize = parseInt(PARAMS.groupMinSize)
          }

          promises.push(
            Async.post('similarity', 'createGroups', { body: d })
          )
        }

        store.dispatch({ type: 'SET_RUPTIVE_DATA', data: data })

        Promise.all(promises)
        .then(resp => {

          let data = resp.map(r => {
            let d = r.data.data

            d.columns = d.groups
            delete d.groups

            d.columns.map(column => {
              column.name  = column.targetSticky.desc
              column.title = column.targetSticky.desc

              column.id = column.groupID

              if(PARAMS.threshold) {
                column.threshold = parseFloat((PARAMS.threshold * .01).toFixed(2))
              }

              column.cards = column.groupStickies.map(card => {
                card.id = `S--${card.guid}--${column.id}--${Math.round(Math.random() * 100000)}`

                return { ...card, ...util.noteMeta(card) }
              })

              delete column.groupStickies

              return column
            })
            return d
          })

          store.dispatch({ type: 'SET_SIMILARITY_DATA', data: data })
        })
        .catch(error => console.error('similarity', error))

      })
      .catch(error => console.error('ruptive', error))
    }
  }
}

export default stage





//
// const board = {
//   columns: [
//     {
//       id: 1,
//       title: "Backlog",
//       cards: [
//         {
//           id: 1,
//           title: "Card title 1",
//           description: "Card content"
//         },
//         {
//           id: 2,
//           title: "Card title 2",
//           description: "Card content"
//         },
//         {
//           id: 3,
//           title: "Card title 3",
//           description: "Card content"
//         }
//       ]
//     },
//     {
//       id: 2,
//       title: "Doing",
//       cards: [
//         {
//           id: 9,
//           title: "Card title 9",
//           description: "Card content"
//         }
//       ]
//     },
//     {
//       id: 3,
//       title: "Q&A",
//       cards: [
//         {
//           id: 10,
//           title: "Card title 10",
//           description: "Card content"
//         },
//         {
//           id: 11,
//           title: "Card title 11",
//           description: "Card content"
//         }
//       ]
//     },
//     {
//       id: 4,
//       title: "Production",
//       cards: [
//         {
//           id: 12,
//           title: "Card title 12",
//           description: "Card content"
//         },
//         {
//           id: 13,
//           title: "Card title 13",
//           description: "Card content"
//         }
//       ]
//     }
//   ]
// };
//
//
//
// groups: Array(3)
// 0:
// groupID: "Group1"
// groupStickies: Array(4)
// 0:
// cosineVal: "0.50075173"
// guid: "f7851370-fca0-11e7-a5f2-9fca1098e0f7"
// header: "{\"description\":\"I need to work with my peers across time zones on marketing planning and campaigns\",\"votes\":4,\"persona\":\"25e84340-fa6c-11e7-b3ad-659b5c75e549\",\"stage\":\"4b062540-f3df-11e7-af1b-d184cbd01b6c\"}"
// __proto__: Object
// 1: {cosineVal: "0.5084193", guid: "b5f32650-fd58-11e7-bde0-c5f1436fb44a", header: "{\"description\":\"Do I fully understand the technolo…\",\"stage\":\"4b062540-f3df-11e7-af1b-d184cbd01b6c\"}"}
// 2: {cosineVal: "0.5198022", guid: "d6e4b850-fca0-11e7-a5f2-9fca1098e0f7", header: "{\"description\":\"Help my team collaborate with exte…\",\"stage\":\"4b062540-f3df-11e7-af1b-d184cbd01b6c\"}"}
// 3: {cosineVal: "0.5000711", guid: "99542e40-fc9f-11e7-b758-4d2b1d9045a6", header: "{\"description\":\"Increase the relevance of marketin…\",\"stage\":\"4b062540-f3df-11e7-af1b-d184cbd01b6c\"}"}
// length: 4
// __proto__: Array(0)
// name: "I need to understand the digital marketing and tech competencies of my marketing colleagues in order to ensure I have the right talent mix"
// targetSticky: {desc: "I need to understand the digital marketing and tec…es in order to ensure I have the right talent mix", guid: "f5ffe820-fca2-11e7-a5f2-9fca1098e0f7", head: "{\"description\":\"I need to understand the digital m…\",\"stage\":\"4b062540-f3df-11e7-af1b-d184cbd01b6c\"}"}
// threshold: 0.5
// __proto__: Object
// 1: {groupID: "Group2", groupStickies: Array(4), targetSticky: {…}, name: "I need to see how much my company brand has gained…n as perceived by my customers and target market.", threshold: 0.5}
// 2: {groupID: "Group3", groupStickies: Array(4), targetSticky: {…}, name: "I need to see how much my brand has been gaining o…n as perceived by my customers and target market.", threshold: 0.5}
// length: 3
