import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Badge } from 'reactstrap';
import classnames from 'classnames';

export default function ({ children: card, dragging, cardHeight, allowRemoveCard, onCardRemove }) {
  return (
    <Card color="primary" body style={{height: `${cardHeight || 200}px`}} className={
      classnames('react-kanban-card note-card shadow', {
        'target-note': card.target, 'disabled': card.disabled, 'react-kanban-card--dragging': dragging
      })}>
      <span>
        <div className='react-kanban-card__title'>
          <span>{card.title}</span>
          {allowRemoveCard && (
            <span style={{ cursor: 'pointer' }} onClick={() => onCardRemove(card)}>
              <FontAwesomeIcon size="sm" icon="trash-alt" />
            </span>
          )}
        </div>
      </span>
      <div className='react-kanban-card__description'>{card.description}</div>

      <div className="icons d-flex justify-content-between align-items-center">
        {card.target ? <FontAwesomeIcon icon="lock" /> : (
          <span><FontAwesomeIcon icon="chart-line" /> {card.cosineVal}</span>
        )}
        <Badge color="secondary">
          <FontAwesomeIcon icon="thumbs-up" /> {card.votes || 0}
        </Badge>
      </div>
    </Card>
  )
}
