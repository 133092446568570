import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink as RRNavLink } from 'react-router-dom';
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
  Button
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NavBar = ({ input, saveTheme }) => {
  const hiddenStages = useSelector(state => state.accessors['HIDDEN_STAGES']) || []

  const dispatch = useDispatch()

  const [topIsOpen, setTopIsOpen] = useState(false);
  const [bottomIsOpen, setBottomIsOpen] = useState(false);

  const toggleStage = (uuid) => {
    if(hiddenStages.includes(uuid)) {
      dispatch({
        type: 'SET_HIDDEN_STAGES',
        data: hiddenStages.filter(id => id !== uuid )
      })
    }
    else {
      dispatch({
        type: 'SET_HIDDEN_STAGES',
        data: [uuid, ...hiddenStages]
      })
    }
  }

  const toggleTop = () => setTopIsOpen(!topIsOpen);
  const toggleBottom = () => setBottomIsOpen(!bottomIsOpen);

  return (
      <>
      <Navbar className="layout-navbar" light expand="md">
        <Container fluid>
          <NavbarText tag={RRNavLink} to="/">
            RUPTIVE SIMILARITIES
          </NavbarText>

          <NavbarToggler onClick={toggleTop} />

          <Collapse isOpen={topIsOpen} navbar>
            <Nav className="ml-auto" navbar>
              {input && (
                <NavItem>
                  <NavLink title="Save Theme" onClick={saveTheme}>
                    <FontAwesomeIcon size="lg" icon="save" />
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink tag={RRNavLink} to="/export" title="Export">
                  <FontAwesomeIcon size="lg" icon="file-export" />
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>

      <Navbar className="layout-navbar" light expand="md">
        <Container fluid>
          <NavbarToggler onClick={toggleBottom} />

          <Collapse isOpen={bottomIsOpen} navbar>
            {input && (
              <Nav navbar>
                {input.map(stage => (
                  <NavItem className="pr-2" key={stage.data.uuid}>
                    <Button outline color="secondary" onClick={() => toggleStage(stage.data.uuid)}>
                      Stage: <strong>{stage.data.title}</strong>
                    </Button>
                  </NavItem>
                ))}
                <NavItem className="pr-2">
                  <Button outline color="secondary">
                    Type: <strong>{input[0].data.type.title}</strong>
                  </Button>
                </NavItem>
                <NavItem className="pr-2">
                  <Button outline color="secondary">
                    Threshold: <strong>{input[0].data.threshold}</strong>
                  </Button>
                </NavItem>
                <NavItem className="pr-2">
                  <Button outline color="secondary">
                    Minimum Group Size: <strong>{input[0].data.groupMinSize}</strong>
                  </Button>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        </Container>
      </Navbar>
      </>
  );
}

export default NavBar;
