import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Card, CardHeader, CardBody, Row, Col, Input } from 'reactstrap';
import Draggable, {DraggableCore} from 'react-draggable';
import Async from 'services/Async';
import Note from '../home/Note';
import util from 'config/util';

function Theme({ stage, stagesOnly, location, params }) {
  const [group, setGroup] = useState(undefined)

  const hiddenStages = useSelector(state => state.accessors['HIDDEN_STAGES']) || []

  const [noteHeight, setNoteHeight] = useState(200)
  const [editName, setEditName] = useState(false)

  useEffect(() => {
    if(location.state && location.state.uuid) {
      setGroup(location.state)
    }
    else if(params.theme) {
      Async.get('ruptive', `api/theme/${params.theme}`)
      .then(({ data }) => setGroup(data))
      .catch(error => console.log(error))

      console.log(group)
    }
  }, [location.state])

  const startDrag = (event, note) => {
    event.target.style.zIndex = "1000"
  }

  const onDrag = el => {
    // console.log('on drag')
  }

  const stopDrag = (event, note, groupID) => {
  }

  const setGroupName = (e, group) => {
    group.name = e.target.value
  }

  const getStage = uuid => {
    return 'the stage'
    // return stagesOnly.find(stage => stage.uuid === uuid)
  }

  const NoteColumn = ({ stageUuid, group, note }) => {

    if(!hiddenStages.includes(util.noteMeta(note).stage)) {
      return (
        <Col xs="12" sm="6" md="4" lg="3" xl="2">
          <Draggable
            handle=".handle"
            position={null}
            grid={[1, 1]}
            scale={1}
            onStart={event => startDrag(event, note)}
            onDrag={onDrag}
            onStop={event => stopDrag(event, note, group.groupID)}>
            <div className="handle">
              <Note note={note} height={noteHeight} stage={getStage(stageUuid)} />
            </div>
          </Draggable>
        </Col>
      )
    } else { return '' }
  }

  const ThemeView = () => (
    <Container fluid>
      <Card key={group.uuid} id={group.uuid} className="group m-3 mb-4">
        <CardHeader onClick={() => setEditName(true)}>
          {editName ? (
            <Input onChange={(e) => setGroupName(e, group)} focus="true" defaultValue={group.name} />
          ):(
            <span>{group.name}</span>
          )}
        </CardHeader>
        <CardBody>
          <Row>
            <Col key={group.targetSticky.guid} xs="12" sm="6" md="4" lg="3" xl="2">
              <Note note={group.targetSticky} height={noteHeight} target={true} stage={getStage(group.stage)} disabled={
                hiddenStages.includes(util.noteMeta(group.targetSticky).stage)
              }/>
            </Col>
            {group.groupStickies.map(note => (
              <NoteColumn key={note.guid} group={group} note={note} stageUuid={group.stage} />
            )).sort((a, b) => b.cosineVal - a.cosineVal)}
          </Row>
        </CardBody>
      </Card>
    </Container>
  )

  return (group ? <ThemeView /> : '');
}

export default Theme;
