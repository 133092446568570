import { Card, CardText, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import util from 'config/util';
import classnames from 'classnames';

function Note({ note, stage, height, target, disabled }) {

  return (
    note && (
      <Card color="primary" body className={classnames('note-card shadow', { 'target-note': target, 'disabled': disabled })}
        style={{height: `${height}px`}}>
        <small className="text-muted mb-2">{stage && stage.title}</small>
        <CardText>{util.noteMeta(note).description}</CardText>
        <div className="icons d-flex justify-content-between align-items-center">
          {target ? <FontAwesomeIcon icon="lock" /> : (
            <span><FontAwesomeIcon icon="chart-line" /> {note.cosineVal}</span>
          )}
          <Badge color="secondary">
            <FontAwesomeIcon icon="thumbs-up" /> {util.noteMeta(note).votes || 0}
          </Badge>
        </div>
      </Card>
    )
  )
}

export default Note
