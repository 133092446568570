import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Card, CardText, CardBody, Row, Col, Input, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Async from 'services/Async';
import util from 'config/util';
import { useHistory } from "react-router-dom";

function Themes({ params }) {
  const history = useHistory();

  const [themeHeight, setThemeHeight] = useState(200)
  const [themes, setThemes] = useState([])

  const windowResize = () => {
    let firstTheme = document.querySelector('.theme-card')

    if(firstTheme) {
      setThemeHeight(firstTheme.offsetWidth)
    }
  }

  useEffect(() => {
    windowResize()

    window.addEventListener('resize', windowResize)

    Async.get('ruptive', `api/themes/${params.journey}`)
    .then(({ data }) => setThemes(data))
    .catch(error => console.log(error))

    return () => {
      window.removeEventListener("resize", windowResize);
    }
  }, [params])

  const openTheme = theme => {
    history.push({
      pathname: '/theme',
      search: `?journey=${params.journey}&theme=${theme.uuid}`,
      state: theme
    });
  }

  const ThemeView = () => (
    <Container fluid>
      <Row>
        {themes.map(theme => (
          <Col key={theme.uuid} xs="12" sm="6" md="4" lg="3" xl="2">

            <Card onClick={() => openTheme(theme)} color="primary" body className="note-card shadow" style={{height: `${themeHeight}px`}}>
              <small className="text-muted mb-2">{theme.created}</small>
              <CardText>{theme.name}</CardText>
              <div className="icons d-flex justify-content-between align-items-center">
                <span><FontAwesomeIcon icon="chart-line" /> {theme.threshold}</span>

                <Badge color="secondary">
                  <FontAwesomeIcon icon="sticky-note" />&nbsp;&nbsp;{theme.groupStickies.length}
                </Badge>
              </div>
            </Card>

          </Col>
        )).sort((a, b) => b.created - a.created)}
      </Row>
    </Container>
  )

  return (themes ? <ThemeView /> : '');
}

export default Themes;
