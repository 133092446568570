import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Navbar } from "./layout";
import DataLoader from 'lib/DataLoader';
import Async from 'services/Async';
import routes from "./routes.js";

const App = (props) => {
  const params = useSelector(state => state.accessors['PARAMS'])
  const input  = useSelector(state => state.accessors['RUPTIVE_DATA'])
  const output = useSelector(state => state.accessors['SIMILARITY_DATA'])

  const [stagesOnly, setStagesOnly] = useState([])

  useEffect(() => {
    if(input) {
      setStagesOnly(input.map(({ data }) => ({
        uuid: data.uuid,
        title: data.title
      })))
    }
  }, [input])

  const saveTheme = () => {
    console.log(output)

    Async.post('ruptive', `api/themes`, {
      body: {
        journey: params.journey,
        stages: output
      }
    })
    .then(({ data }) => {
      console.log(data)
    })
  }

  const mapRoutes = routes => {

    return routes.map((prop, key) => {
      const Component = prop.component;

      return (
        <Route exact
          path={prop.path}
          render={props => <Component {...props} stagesOnly={stagesOnly} stages={output} params={params} />}
          key={key}
        />
      )
    });
  };
  return (
    <>
    {(params && (params.allParams)) ? (!!output || <DataLoader />) : (
      params.journey ? '' : <h5 className="text-center mt-5">Please check missing parameters</h5>
    )}

    <BrowserRouter>
      <Navbar input={input || null} saveTheme={saveTheme} />
      <Switch>{mapRoutes(routes)}</Switch>
      {/*<Footer />*/}
    </BrowserRouter>
    </>
  );
};

export default App;
