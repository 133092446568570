import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import ReactDOM from "react-dom";
import { Container } from 'reactstrap';
import Board, { moveCard } from "lib/react-kanban";
import './styles.scss';

export default function Home({ stages, stagesOnly }) {
  const dispatch = useDispatch()

  const hiddenStages = useSelector(state => state.accessors['HIDDEN_STAGES']) || []

  const [noteHeight, setNoteHeight] = useState(230)
  // const [groups, setGroups] = useState([])
  // const [editTheme, setEditTheme] = useState(false)

  // const windowResize = () => {
  //   let firstNote = document.querySelector('.note-card')
  //
  //   if(firstNote) {
  //     setNoteHeight(firstNote.offsetWidth)
  //   }
  // }
  //
  // useEffect(() => {
  //   windowResize()
  //
  //   window.addEventListener('resize', windowResize)
  //
  //   return () => {
  //     window.removeEventListener("resize", windowResize);
  //   }
  // }, [])

  return (
    <Container fluid>
      {stages ? stages.map(stage => (
        <Board key={stage.id}
          disableColumnDrag
          allowRemoveLane
          allowRenameColumn
          allowRemoveCard
          onCardDragEnd={console.log}
          onColumnRename={console.log}
          onLaneRemove={console.log}
          onCardRemove={console.log}
          onLaneRename={console.log}
          initialBoard={stage}
          cardHeight={noteHeight}
        />
      )) : ''}
    </Container>
  );
}
