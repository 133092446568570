import params from '../async/params';
import groups from '../async/groups';

const api = store => next => action => {
  next(action)

  // GET ASYNC DATA
  params(store)
  groups(store)
}

export default api
