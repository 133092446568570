const config = {
  isDevelopment: () => {
    return process.env.NODE_ENV === 'development';
  },
  toPascalCase: string => {
    return string.replace(/_/g, ' ').replace(/\w\S*/g, m => m.charAt(0).toUpperCase() + m.substr(1))
  },
  chainCheck: (fn, defaultVal = undefined) => {
    try { return fn() } catch (e) {
      return defaultVal
    }
  },
  noteMeta: note => {
    return JSON.parse(note.header || note.head || "{}")
  }
}

export default config
