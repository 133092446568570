import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function DataLoader() {
  return (
    <div className="data-loading">
      <div className="data-loading-message">
        <FontAwesomeIcon icon="spinner" pulse size="2x" />
      </div>
    </div>
  )
}

export default DataLoader;
