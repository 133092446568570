import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
require('./icons');

const store = configureStore()

const renderApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  );
}

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', renderApp)
}

renderApp()

reportWebVitals();
