import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import monitorReducersEnhancer from './enhancers/monitorReducers'
import loggerMiddleware from './middleware/logger'
import apiMiddleware from './middleware/api'
import rootReducer from './reducers'

export default function configureStore(preloadedState) {
  const middlewares = [
    loggerMiddleware,
    thunkMiddleware,
    apiMiddleware
  ]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [
    middlewareEnhancer,
    monitorReducersEnhancer
  ]
  const composedEnhancers = composeWithDevTools(...enhancers)

  const store = createStore(rootReducer, preloadedState, composedEnhancers)

  store.dispatch({
    type: 'INIT',
    text: 'initialize store'
  })

  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
  }

  return store
}
